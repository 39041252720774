<template>
  <div class="">
    <b-card>
      <div class="mb-3">
        <b-nav pills>
          <b-button @click="add()" variant="dark">등록</b-button>
        </b-nav>
      </div>
      <c-table :table-data="items" :fields="fields" :perPage.sync="perPage" :caption="total_cnt+'개'" @btn-clicked="btnAction"></c-table>
    </b-card>
  </div>
</template>
<script>
  import cTable from '@/views/TableBase.vue'
  import {getJson, postJson} from '@/shared/api'
  import * as moment from 'moment-timezone';
  import * as utils from '@/shared/utils'

  export default {
    name: "TimeSale",
    components: {cTable},
    data() {
      return {
        items: [],
        perPage: 20,
        total_cnt: 0,
        goods: {},
        now_tab: 'man',
        //번호 제목 적용일 관리자 상태 관리(복사 삭제 수정) 미리보기
        fields: [
          {key: 'id', label: '번호', style: ''},
          {key: 'gender', label: '성별'},
          {key: 'startdt', label: '시작일'},
          {key: 'enddt', label: '종료일'},
          {key: 'cnt_goods', label: '상품수'},
          {key: 'cnt_soldout', label: '품절상품수'},
          {key: 'status', label: '상태'},
          {key: 'manager', label: '관리자'},
          {key: '_actions', label: '관리', buttons: [
              {label: '삭제', variant: 'danger', event: 'delete'},
              {label: '수정', variant: 'primary', event: 'modify'},
            ]
          },
        ]
      }
    },
    async created() {
      this.$utils.getStatus(this.$options.name, this, 'perPage');

      this.getList();
    },
    watch: {
      perPage: function() {
        this.$utils.setStatus(this.$options.name, this, 'perPage');
      }
    },
    methods: {
      async getList() {
        let j = await getJson(`/godo/getTimesaleSetList`);
        this.goods = j.goods;
        this.total_cnt = j.list.length;
        j.list.forEach(e => {
          if (e.category === '010') e.gender = 'man';
          else if (e.category === '009') e.gender = 'woman';
          else e.gender = e.category;
          e.startdt = moment(e.startdt).format('YYYY-MM-DD HH:mm:ss');
          e.enddt = moment(e.enddt).format('YYYY-MM-DD HH:mm:ss');

          let cnt_goods = 0;
          let cnt_soldout = 0;
          e.goods.split(',').forEach(goodsno => {
            let goods = this.goods[goodsno];
            if (goods) {
              cnt_goods++;
              if (goods.total_stock <= 0) cnt_soldout++;
            }
          });
          e.cnt_goods = cnt_goods;
          e.cnt_soldout = cnt_soldout;
        });
        this.items = j.list;
      },
      async add() {
        location.href = '/#/timesale/set?mode=add';
      },
      async btnAction(row, event) {
        let obj = row.item;
        switch (event) {
          case 'delete':
            if (confirm('삭제하시겠습니까?')) {
              let result = await postJson('/godo/deleteTimesaleSet', {id: obj.id, gender: this.now_tab});
              if (result) {
                alert('삭제되었습니다');
              }
            }
            break;
          case 'modify':
            location.href = '/#/timesale/set?mode=modify&id=' + obj.id;
            break;
        }
      },
    }
  }
</script>
